<template>
  <div class="px-5" id="page-support">
    <h5 class="mb-6 ml-2">API Nycklar</h5>

    <APIKeysTable
      :items="apiKeys"
      @createApiKeyClicked="createApiKeyClicked"
      @selectApiKeyClicked="selectApiKeyClicked"
      @deleteApiKeyClicked="deleteApiKeyClicked"
      @toastr="toastr"
    />
    <div class="scroll-to-container"></div>

    <!-- Creating Todo  -->
    <b-card
      title=""
      class="mb-2 edit-apikey-container"
      ref="edit-apikey"
      id="edit-apikey"
      hide-footer
      v-if="creating || editing"
    >
      <b-form class='d-flex flex-column' @submit="saveApiKey($event)">

        <b-card
          title="API Nyckeldata"
        >
          <b-form-group label="Förening" label-for="input-company_id">
            <b-form-select id="input-company_id" placeholder="Välj förening" v-model="form.company_id" :options="companyOptions">
            </b-form-select>
          </b-form-group>


          <b-form-group label="Namn på nyckeln" label-for="input-name">
              <b-form-input type="text" v-model="form.name" />
              <b-form-text id="input-live-help">Till exempel "Hedlundas SPAR Prod"</b-form-text>
          </b-form-group>
          
          <b-card
            title="SPAR Inställningar"
            class="mb-8"
            >
            <b-form-group label="KundNrLeveransMottagare" label-for="input-KundNrLeveransMottagare">
                <b-form-input type="text" v-model="form.spar_data.KundNrLeveransMottagare" />
            </b-form-group>

            <b-form-group label="KundNrSlutkund" label-for="input-KundNrSlutkund">
                <b-form-input type="text" v-model="form.spar_data.KundNrSlutkund" />
            </b-form-group>

            <b-form-group label="Organisationsnummer" label-for="input-orgNr">
                <b-form-input type="text" v-model="form.spar_data.orgNr" :state="validOrgNr" required/>
            </b-form-group>

            <b-form-group label="slutAnvandarId" label-for="input-slutAnvandarId">
                <b-form-input type="text" v-model="form.spar_data.slutAnvandarId" />
            </b-form-group>

            <b-form-group label="UppdragId" label-for="input-UppdragId">
                <b-form-input type="text" v-model="form.spar_data.UppdragId" />
            </b-form-group>


            <b-form-group label="Client-ID" label-for="input-client_id">
              <b-form-input type="text" v-model="form.client_id" />
              <b-form-text id="input-live-help">Systemet genererar detta automatiskt</b-form-text>
            </b-form-group>

            <b-form-group label="Client-Secret" label-for="input-client_secret">
              <b-form-input type="text" v-model="form.client_secret" />
              <b-form-text id="input-live-help">Systemet genererar detta automatiskt</b-form-text>
            </b-form-group>

            <b-button type="submit" variant="primary" :disabled="disabledSave">Spara</b-button>
          </b-card>


        </b-card>

      </b-form>
    </b-card>

  </div>
</template>

<style lang="scss" scoped>
.checkbox.checkbox-light-success > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import APIKeysTable from '@/view/pages/ml/apikeys/APIKeysTable.vue';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'apikeys',
  components: {
    APIKeysTable,
  },
  computed: {
    validOrgNr() {
      const orgNr = this.form.spar_data.orgNr + '';

      if (orgNr.indexOf('-') >= 0) {
        return false;
      }
      
      if (orgNr.length !== 10) {
        return false;
      }

      return true;
    },
    disabledSave(){
      return false;
    }
  },
  mounted() {
    this.loadData();
  },
  validations: {

  },
  data() {
    return {
      companyOptions: [],
      statusOptions: [
        { value: 'CLAIMED', text: 'Aktivt' },
        { value: 'UNCLAIMED', text: 'Väntar' },
        { value: 'REVOKED', text: 'Spärrat' },
      ],
      serviceOptions: [
        { value: 'fortnox', text: 'Fortnox' },
        { value: 'spar', text: 'SPAR' },
        { value: 'dintero', text: 'Dintero' },
      ],
      form: {
        id: '',
        company_id: '',
        spar_data: {},
        created_at: '',
        updated_at: '',
        status: '',
        public_token: '',
        access_token: '',
        service: '',
        orgNr: null
      },
      creating: false,
      editing: false,
      apiKeys: []
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadApiKeys();
      }
    }
  },
  methods: {

    async loadData() {
      this.loadCompanies();
      this.loadApiKeys();
    },

    createApiKeyClicked() {
      this.resetForm()
      this.creating = true;
      this.editing = false;

      this.scrollToEditApiKey();
    },

    loadCompanies() {
      axios 
        .get('/companies')
        .then(res => {
          this.companies = res.data;

          this.companyOptions = [];

          for (const c of this.companies) {
            this.companyOptions.push({ value: c.company_id, text: c.name });
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', 'Server Fel', 'Kunde inte lista föreningar');
        });
    },

    createApiKey() {
      axios
        .post('/apikey', this.form )
        .then(res => {
          if (res.status === 201) {
            this.apiKeys.push(res.data);
            this.toastr('success', 'OK', 'Api nyckeln har skapats');
          }
          else if (res.status === 406) {
            this.toastr('danger', 'Fel', 'Det finns redan en API nyckel för den tjänsten och föreningen');
          }
          else {
            this.toastr('danger', 'Fel', 'Kunde inte skapa Api nyckel');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', 'Server Fel', 'Kunde inte skapa Api nyckeln');
        });
    },

    deleteApiKeyClicked(id) {
      axios
        .delete(`/apikey?id=${id}`)
        .then(res => {
          this.apiKeys = this.apiKeys.filter(item => item.id !== id);

          this.creating = false;
          this.editing = false;

          this.toastr('success', 'OK', 'Api nyckeln togs bort');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', 'Server Fel', 'Kunde inte ta bort Api nyckel');
        });
    },

    selectApiKeyClicked(id) {

      axios
        .get(`/apikey?id=${id}`)
        .then(res => {
          this.apiKeySelected = res.data;
          this.form = res.data;
          this.editing = true;
          this.scrollToEditApiKey(true);
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', 'Server Fel', 'Kunde inte hämta Api nyckel');
        });
    },

    loadApiKeys() {

      this.apiKeys = [];
      axios
        .get(`/apikeys`)
        .then(res => {
          if (res.status === 200) {
            this.apiKeys = res.data;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', 'Server Fel', 'Kunde inte lista API nycklar');
        });
    },

    scrollToEditApiKey(withId = false) {
      var that = this;
      setTimeout(function () {
        const el = withId ? document.getElementById('support-task-table') : that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },

    saveApiKey(e) {
      if (e !== undefined) {
        e.preventDefault();
      }

      //let orgNr = this.form.spar_data.orgNr;
      //this.form.spar_data.orgNr = orgNr.includes('-') && orgNr.indexOf('-') === 6 ? orgNr.replace('-','') : orgNr;

      if (this.creating) {
        this.createApiKey();
      }
      else {
        axios
        .put('/apikey', this.form)
        .then(res => {
          if (res.status === 200) {
            this.form = res.data;
            this.toastr('success', 'OK', 'Api nyckel uppdaterades');
            this.creating = false;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', 'Server Fel', 'Kunde inte uppdatera Api nyckel');
        });
      }
    },

    resetForm(){
      this.form = {
          id: '',
          company_id: '',
          service: '',
          access_token: '',
          public_token: '',
          spar_data: {},
          status: ''
      }
    },

    toastr(type, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 2000,
        appendToast: true,
        variant: type
      });
    }
  }
};
</script>
